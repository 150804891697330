<template> 
    <v-menu id="loggedinmenu" transition="slide-y-transition" content-class="elevation-1"
        bottom
        v-if="user"
        min-width="250"
        rounded
        :close-on-content-click="false"
        offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }" outlined dark class="ma-0 pa-1" color="custom-accent">
                        <v-avatar class="custom-accent" size="40" dark>
                            <span class="text-capitalize custom-white--text">{{ user.name.charAt(0)}}{{ user.name.charAt(1) }}</span>
                        </v-avatar>
                    </v-btn>
                </template>
                <span>{{user.name}}</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-list text>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-avatar
                        class="custom-accent--text"
                        outlined
                        size="50">
                            <span class="headline pa-0 ma-0 text-capitalize">{{ user.name.charAt(0)}}{{ user.name.charAt(1) }}</span>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ user.name }}</v-list-item-title>
                        <v-list-item-subtitle>@{{ user.username }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>

                <v-list-item :to="{ path: '/' }">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("home") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item :to="{ path: '/admin' }">
                    <v-list-item-icon>
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("admin") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="logout()">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-card>
    </v-menu>
</template>
<script>

import { mapActions,mapGetters } from "vuex";
export default({
    name: 'LoggedInMenu',
    data() {
        return {
        }
    },
    async created(){
    },
    computed: {
        ...mapGetters({
            user:'getUser'
        }),
    },
    watch:{},
    methods: {
        logout() {
            this.destroyToken().then(() => {
                this.$router.push({ name: "login" });
            });
        },

        ...mapActions(["destroyToken"]),
    }
})
</script>
<style scoped>
</style>